import { useEffect, useMemo } from 'react';
import { Form } from 'antd';
import { RuleModel } from '../../../models/RulesModel';

export const useWatchIsDocumentProvided = (
    manuallyAddedCriteriaCodes: number[] = [],
    criteria: RuleModel[] = [],
    alertsIndicatorsCodes: number[] = [],
) => {
    const form = Form.useFormInstance();
    const isDocumentProvided: number = Form.useWatch('isDocumentsProvided', form);
    const criteria215 = useMemo(() => criteria.find((item) => item.code === '215')?.id, [criteria]);
    useEffect(() => {
        if (isDocumentProvided === 0 && criteria215 && !alertsIndicatorsCodes.includes(criteria215)) {
            form.setFieldValue('manuallyCriteria', [...manuallyAddedCriteriaCodes, criteria215]);
        } else if (isDocumentProvided !== 0 && criteria215 && manuallyAddedCriteriaCodes.includes(criteria215)) {
            form.setFieldValue(
                'manuallyCriteria',
                manuallyAddedCriteriaCodes.filter((code) => code !== criteria215),
            );
        }
        // Додавати та знімати критерій 215 лише при зміні значення isDocumentProvided
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isDocumentProvided]);
};
